<template>
  <div>
    <settings></settings>
  </div>
</template>

<script>
  import Settings from '../../components/Settings/Settings';

  export default {
    name: 'Settings',
    components: {
      settings: Settings
    },
    data() {
      return {

      };
    }
  };
</script>
